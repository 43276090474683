import { Controller } from "@hotwired/stimulus";
import Autocomplete from "bootstrap5-autocomplete";

export default class extends Controller {
  static targets = [
    "nameInput",
    "addressInput",
    "addressNumberInput",
    "addressSuffixInput",
    "cityInput",
    "cocNumberInput",
    "postalCodeInput",

    "nameOutput",
    "addressOutput",
    "addressNumberOutput",
    "addressSuffixOutput",
    "cityOutput",
    "cocNumberOutput",
    "postalCodeOutput",

    "detailsPanel",
    "loadingPanel"
  ];

  static values = {
    indexPath: String,
    showPath: String,
  };

  connect() {
    this.authenticityParam = {
      authenticity_token: $("input[name=authenticity_token]").val(),
    };
    this.inputContainer = this.nameInputTarget.parentNode;

    this.initAutocomplete();

    if (this.cityInputTarget.value != "") {
      this.showDetailsPanel();
    }
  }

  // Helpers

  escapeHtml = (text) => {
    let p = document.createElement("p");
    p.appendChild(document.createTextNode(text));
    return p.innerHTML;
  };

  // Setup

  initAutocomplete() {
    this.inputContainer.classList.add("position-relative");

    Autocomplete.init("#" + this.nameInputTarget.id, {
      debounceTime: 500,
      hiddenValue: true,
      labelField: "name",
      liveServer: true,
      onRenderItem: this.autocompleteLabelTemplate,
      onSelectItem: this.autocompleteOnSelectItem,
      queryParam: "name",
      server: this.indexPathValue,
      serverParams: this.authenticityParam,
      showAllSuggestions: true,
      valueField: "name",
    });
  }

  // templates

  autocompleteLabelTemplate = (item, _label, _inst) => `
    <strong>${this.escapeHtml(item.name)}</strong><br />
    ${this.escapeHtml(item.address_summary)}
  `;

  // Actions

  autocompleteOnSelectItem = (item, _inst) => {
    this.set(item);
  };

  set(item) {
    this.cocNumberInputTarget.value = item.id;

    this.loadingPanelTarget.classList.remove("d-none");
    this.inputContainer.classList.add("d-none");

    const urlParams = new URLSearchParams(this.authenticityParam);

    let showPath =
      this.showPathValue.replace(/:id/, item.id) + "?" + urlParams.toString();

    fetch(showPath)
      .then((response) => response.json())
      .then((item) => {
        this.nameInputTarget.value = item.name;
        this.addressInputTarget.value = item.address;
        this.addressNumberInputTarget.value = item.address_number;
        this.addressSuffixInputTarget.value = item.address_suffix;
        this.cityInputTarget.value = item.city;
        this.cocNumberInputTarget.value = item.id;
        this.postalCodeInputTarget.value = item.postal_code;

        this.nameOutputTarget.innerText = item.name;
        this.addressOutputTarget.innerText = item.address;
        this.addressNumberOutputTarget.innerText = item.address_number;
        this.addressSuffixOutputTarget.innerText = item.address_suffix;
        this.cityOutputTarget.innerText = item.city;
        this.postalCodeOutputTarget.innerText = item.postal_code;

        this.showDetailsPanel();
      });
  }

  reset() {
    this.nameInputTarget.value = "";
    this.addressInputTarget.value = "";
    this.addressNumberInputTarget.value = "";
    this.addressSuffixInputTarget.value = "";
    this.cityInputTarget.value = "";
    this.cocNumberInputTarget.value = "";
    this.postalCodeInputTarget.value = "";

    this.nameOutputTarget.innerText = "";
    this.addressOutputTarget.innerText = "";
    this.addressNumberOutputTarget.innerText = "";
    this.addressSuffixOutputTarget.innerText = "";
    this.cityOutputTarget.innerText = "";
    this.postalCodeOutputTarget.innerText = "";

    this.hideDetailsPanel();
  }

  showDetailsPanel() {
    this.detailsPanelTarget.classList.remove("d-none");
    this.loadingPanelTarget.classList.add("d-none");
    this.inputContainer.classList.add("d-none");
  }

  hideDetailsPanel() {
    this.detailsPanelTarget.classList.add("d-none");
    this.inputContainer.classList.remove("d-none");
  }
}
