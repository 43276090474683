/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import{ driver } from 'driver.js';

window.driver = driver;
window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
            navigator.serviceWorker.register('/service-worker.js', { scope: "/" })
                .then(function(registration) {
                    // console.log('[ServiceWorker Client]', 'registration succesful with scope: ', registration.scope);
                }, function(err) {
                    console.log('[ServiceWorker Client]', 'registration failed: ', err);
                });
        });
    }
}

if (process.env.RAILS_ENV === 'development' && process.env.ENABLE_DEVELOPMENT_SERVICE_WORKER == true) {
    registerServiceWorker();
} else if (process.env.RAILS_ENV === 'development') {
    console.log('ServiceWorker not registered, because rails is running in development mode');
} else {
    registerServiceWorker();
}
